import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import posed from 'react-pose'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Image from '../components/image'
import BannerImpact from '../components/banner-impact'
import Modal from '../components/modal'
import ImgWithSVGSupport from '../components/ImgWithSVGSupport'
import ProjectPost from '../components/project-post'

const FadeUp = posed.div({
  exit: {
    y: '100px',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: { duration: 1000 },
  },
})

const FadeLeft = posed.div({
  exit: {
    x: '-100px',
    opacity: 0,
  },
  enter: {
    x: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: props => ({ duration: props.duration + 1000 }),
  },
})

const FadeRight = posed.div({
  exit: {
    x: '100px',
    opacity: 0,
  },
  enter: {
    x: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: props => ({ duration: props.duration + 1000 }),
  },
})

const IndexPage = ({ data }) => {
  const { actionSVG, educationSVG, inspirationSVG, individual_projects } = data
  const { edges: projectList } = individual_projects
  const [visibleModal, setVisibleModal] = useState(false)
  const handleModal = isVisible => {
    document.body.className = isVisible ? 'modal-open' : ''
    setVisibleModal(isVisible)
  }
  return (
    <>
      <Layout>
        <SEO title="World’s first self-funding Cryptocurrency Impact project" />
        <div className="hero-header bg-green pt-4">
          <div className="container pb-5">
            <div className="row text-center text-lg-left">
              <div className="col-lg col-xl mt-lg-5">
                <FadeUp initialPose="exit" pose="enter">
                  <h1 className="display-4 font-weight-bold text-white mb-4">
                    World’s first <br className="d-none d-md-block" />
                    self-funding cryptocurrency impact project
                  </h1>
                </FadeUp>
                <p className="h5 text-white">
                  We exist to educate, act and inspire.
                </p>
                <p className="h5 text-white">
                  Our education platform aims is a holistic healing tool, for
                  your mind, body and soul. To take that further, we continue to
                  educate on how we can work collectively to heal our planet.
                </p>
                <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-center align-items-center justify-content-lg-start mt-4">
                  <FadeLeft initialPose="exit" pose="enter" duration={500}>
                    <Link
                      to="/make-your-impact/#donation-section"
                      className="btn btn-lg btn-outline-warning mt-4"
                    >
                      TAKE ACTION
                    </Link>
                  </FadeLeft>
                  <FadeRight initialPose="exit" pose="enter" duration={500}>
                    <button
                      type="button"
                      className="btn btn-lg btn-outline-warning mt-4 ml-sm-5 btn-icon-arrow"
                      onClick={() => handleModal(true)}
                    >
                      WATCH VIDEO
                    </button>
                  </FadeRight>
                </div>
              </div>
              <FadeRight
                initialPose="exit"
                pose="enter"
                duration={500}
                className="d-none d-lg-block col-lg-5 col-xl mt-5"
              >
                <Image filename="home/hero-header.png" className="img-fluid" />
              </FadeRight>
            </div>
            <div className="embed-responsive embed-responsive-16by9 mt-5 main-video-into rounded-lg">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/lmU1CdZx7II?autoplay=1"
                title="Introducing Energi Impact"
                rameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="container py-4 text-center">
          <p className="h5">
            This is all made possible through cryptocurrency; Energi.{' '}
          </p>
          <p className="h5">
            Through our treasury system, we reinvest our currency into{' '}
          </p>
          <p className="h5">
            Energi Impact; to serve humanity as best we can.{' '}
          </p>
          <a
            href="https://www.energi.world/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm btn-success mt-3"
          >
            ENERGI WEBSITE
          </a>
        </div>
        <section id="education-section" className="row p-0 m-0">
          <div className="col-lg-6 col-xl-4 p-0">
            <Image
              filename="home/empower-yourself.jpg"
              className="img-fluid img-main-hero"
              alt=""
            />
            <div className="get-educated-box px-3 px-sm-2 py-4 py-sm-5">
              <h2 className="mb-4 font-weight-bold text-uppercase">
                Empower Yourself
              </h2>
              <p>
                You can create your dream life. Utilizing the Law of Attraction,
                manifestation, meditations, philosophy and techniques; we teach
                you exactly how to take back your power and become your best
                self.
              </p>
              <div className="text-center btn-absolute-bottom w-100 mb-3 px-3">
                <Link
                  to="/empower-yourself/"
                  className="btn btn-lg btn-success btn-block"
                >
                  GET EDUCATED
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4 p-0">
            <Image
              filename="home/heal-youself.jpg"
              className="img-fluid img-main-hero"
              alt=""
            />
            <div className="get-educated-box px-3 px-sm-2 py-4 py-sm-5">
              <h2 className="mb-4 font-weight-bold text-uppercase">
                Heal Yourself
              </h2>
              <p>
                If you want to change the world, start with yourself first. This
                platform provides wisdom for you to heal your physical, mental
                and spiritual body so that we can show up as our best selves in
                every moment.
              </p>
              <div className="text-center btn-absolute-bottom w-100 mb-3 px-3">
                <Link
                  to="/heal-yourself/"
                  className="btn btn-lg btn-success btn-block"
                >
                  GET EDUCATED
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-xl-4 p-0">
            <Image
              filename="home/heal-your-world.jpg"
              className="img-fluid img-main-hero"
              alt=""
            />
            <div className="get-educated-box px-3 px-sm-2 py-4 py-sm-5">
              <h2 className="mb-4 font-weight-bold text-uppercase">
                Heal Your World
              </h2>
              <p>
                Our planet needs our help, and knowing how you can take action
                is the first step. Get educated on how you can help create
                worldwide change.
              </p>
              <div className="text-center btn-absolute-bottom w-100 mb-3 px-3">
                <Link
                  to="/heal-your-world/"
                  className="btn btn-lg btn-success btn-block"
                >
                  GET EDUCATED
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="container py-4">
          <p className="h5 text-center my-4">
            Our core purpose is to educate. By harbouring this knowledge we can
            make empowered choices to change our individual lives, wider
            community and subsequently the world.{' '}
          </p>
        </div>

        <section className="bg-green py-5 text-center text-sm-left">
          <div className="container text-white">
            <h2 className="mb-5">Our Vision</h2>
            <div className="row justify-content-center pb-5">
              <div className="col-md mt-5">
                <div className="vision-icon">
                  <div className="hexagon shadow">
                    <ImgWithSVGSupport
                      {...educationSVG}
                      loading="lazy"
                      className="d-block mx-auto vision-svg-icon"
                      width="70"
                    />
                  </div>
                </div>
                <h4 className="vision-name mt-5 text-center">EDUCATION</h4>
                <p className="text-center">
                  An empowering, accurate platform for the individual; a trusted
                  place to gain knowledge on just about everything.{' '}
                </p>
              </div>
              <div className="col-md mt-5">
                <div className="vision-icon">
                  <div className="hexagon shadow">
                    <ImgWithSVGSupport
                      {...actionSVG}
                      loading="lazy"
                      className="d-block mx-auto vision-svg-icon"
                      width="45"
                    />
                  </div>
                </div>
                <h4 className="vision-name mt-5 text-center">ACTION</h4>
                <p className="text-center">
                  We run initiatives and community gatherings such as beach
                  clean ups, social media challenges, orphanage visits and more!
                </p>
              </div>
              <div className="col-md mt-5">
                <div className="vision-icon last-child">
                  <div className="hexagon shadow">
                    <ImgWithSVGSupport
                      {...inspirationSVG}
                      loading="lazy"
                      className="d-block mx-auto vision-svg-icon"
                      width="65"
                    />
                  </div>
                </div>
                <h4 className="vision-name mt-5 text-center">INSPIRATION</h4>
                <p className="text-center">
                  Once we are collectively educated and know how to take action
                  for the greater good; the last thing is to feel completely
                  fulﬁlled by it. Our work aims to inspire you further.
                </p>
              </div>
            </div>

            <h2>Our Projects</h2>
            <div className="row">
              {projectList.map((project, index) => (
                <div
                  className="d-flex flex-column col-sm-6 col-lg-4 mt-5 mt-sm-4"
                  key={index}
                >
                  <ProjectPost {...project} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="container py-4">
          <p className="h5 text-center my-4">
            By pioneering an avenue for actioned change we not only can begin to
            make the changes we wish to see, but inspire others to do the same.
          </p>
        </div>

        <BannerImpact />

        <Modal
          showModal={visibleModal}
          handleClose={() => handleModal(false)}
          title="Introducing Energi Impact"
        >
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/yMg2npiLDMw?autoplay=1"
              title="Introducing Energi Impact"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </Modal>
      </Layout>
    </>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query {
    actionSVG: file(relativePath: { eq: "home/action.svg" }) {
      ...PlaceholderImageWithSVG
    }
    educationSVG: file(relativePath: { eq: "home/education.svg" }) {
      ...PlaceholderImageWithSVG
    }
    inspirationSVG: file(relativePath: { eq: "home/inspiration.svg" }) {
      ...PlaceholderImageWithSVG
    }
    individual_projects: allWordpressPost(
      sort: { fields: [date] }
      filter: { categories: { elemMatch: { name: { eq: "Projects" } } } }
    ) {
      edges {
        node {
          title
          path
          acf {
            project_single_post {
              our_project {
                our_project_image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                our_project_description
              }
            }
          }
        }
      }
    }
  }
`
